import { Injectable } from '@angular/core';


// Interface
import { HttpClient } from '@angular/common/http';
import { map, Observable, switchMap } from 'rxjs';
import { environment } from 'environments/environment';
import { AppService } from 'app/services/app.service';

@Injectable()
export class TrackingProvider {
  constructor(public http: HttpClient, private appService: AppService) { }


  public get(id: string): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.TRACKING}/${id}?suscription=${this.appService.getViewSuscription()}`);
  }
  
  getFile(fileName: string): Observable<any> {
    const encodedNameFile = encodeURIComponent(fileName);
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.TRACKING}/get-file/${encodedNameFile}`);
  }

  private blobToBase64(blob: Blob): Observable<string> {
    const reader = new FileReader();
    return new Observable<string>((observer) => {
      reader.onloadend = () => {
        observer.next(reader.result as string);
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
      reader.readAsDataURL(blob);
    });
  }
}
